import { useState } from 'react';

function App() {
  const [videoUrl, setVideoUrl] = useState('');

  const handleChange = (event) => {
    setVideoUrl(event.target.value);
  };

  const handlePaste = async () => {
    const text = await navigator.clipboard.readText();
    setVideoUrl(text);
  };

  const handleClear = () => {
    setVideoUrl("");
  };
  const handleFocus = (event) => event.target.select();

  const apiSrc = `https://convertapi.dev/api/single/mp3?url=${videoUrl}`;

  return (
    <div className="App">
      <header className="App-header">
        <div className="flex justify-center items-center h-screen bg-white">
          <div className="p-2 text-center max-w-screen-lg text-black rounded w-full">
            <div className='bg-gray-50 rounded-tr-lg p-2 '>
              <p className='sm:pl-10 sm:text-6xl text-5xl text-black text-left font-black'>
                MP3 Machine <a className='hover:underline' href="https://raviheyne.com" target="_blank" rel="noopener noreferrer">
                  <span className='text-sm font-bold'>by Ravi</span>
                </a>
              </p>
            </div>
            <div className="p-14 sm:p-14 sm:px-40 md:px-60 text-center max-w-screen-lg bg-sky-950 rounded-br rounded-bl w-full">
              <div className="flex items-center justify-between">
                <input
                  type="text"
                  value={videoUrl}
                  placeholder='Insert link here'
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded text-black"
                  onFocus={handleFocus}
                />
                <button
                  onClick={handlePaste}
                  className="ml-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors active:ring"
                >
                  Paste
                </button>


                <button
                  onClick={handleClear}
                  className="ml-2 p-2 bg-gray-500 text-white rounded hover:bg-black transition-colors active:ring"
                >
                  Clear
                </button>
              </div>
              <div className="w-full h-64 mt-4">
                {videoUrl && videoUrl.trim() !== "" ? (
                  <iframe
                    id="singleButtonApi"
                    title="downloadButton"
                    src={apiSrc}
                    width="100%"
                    height="100%"
                    allowTransparency="true"
                    scrolling="no"
                    className="w-full rounded border-0"
                  ></iframe>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
